<template>
  <v-card flat>
    <AdminOwners
      has-edit-roles
      @edit-roles="updateUser(true, $event)"
    ></AdminOwners>

    <v-card-title class="xflex xflex-row xjustify-between xgap-2 xitems-center">
      <app-search
        :api="`api/workspaces/${user.team_id}/users-search?with=roles`"
        item-text="real_name"
        item-value="id"
        class="md:xmax-w-[300px] xmax-w-full"
        @selected="onSelect"
        @cleared="fetchUsers"
      ></app-search>
      <div
        :class="[
          tabletDown ? 'xw-full' : '',
          mobile ? 'xflex-col' : 'xflex-row',
        ]"
        class="xflex xjustify-end xgap-2 xitems-center"
      >
        <SyncToSlack
          :block="smDown"
          v-if="user"
          :team-id="user.team_id"
        ></SyncToSlack>
        <v-btn
          color="primary"
          @click="updateRole(true, null)"
          class="text-none"
          :block="smDown"
          depressed
          v-if="$can.and('add_roles')"
        >
          <v-icon left>mdi-plus</v-icon> New Role
        </v-btn>
        <btn-list
          v-if="$can.and('update_roles')"
          elevation="0"
          :block="smDown"
          label="Manage Roles"
          depressed
          btn-color="primary"
        >
          <v-list dense max-height="400" class="overflow-y-auto">
            <v-list-item
              v-for="roleW in workspaceRoles"
              :key="roleW.id"
              @click="updateRole(true, roleW)"
            >
              <v-list-item-title>
                {{ roleW.normalize_name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </btn-list>
      </div>
    </v-card-title>
    <v-card-text>
      <app-table
        class="xmin-h-full"
        :headings="headers"
        :items="items"
        :loading="loading"
      >
        <template v-slot:actions="{ item }">
          <div class="xflex xflex-row xjustify-center xitems-center xgap-2">
            <btn-tooltip
              v-if="$can.or('assign_roles|revoke_roles')"
              @click="updateUser(true, item)"
              tip="Edit Roles"
              small
              color="primary"
            >
              <v-icon left>mdi-account-cog</v-icon>
              Update Roles & Permissions
            </btn-tooltip>
          </div>
        </template>
        <template v-slot:user="{ item }">
          <avatar size="40" :user="item" with-name></avatar>
        </template>
        <template v-slot:roles="{ item }">
          <div class="xflex md:xflex-row xflex-col xmy-[1px] xgap-[2px]">
            <v-chip small label v-for="role1 in item.roles" :key="role1.id">
              {{ role1.normalize_name }}
            </v-chip>
          </div>
        </template>
      </app-table>
    </v-card-text>

    <UserRolePermissionModal
      v-model="userModal"
      :user="selectedUser"
      @changes="handleChanges"
    ></UserRolePermissionModal>

    <CustomRoleModal
      v-model="roleModal"
      :role="selectedRole"
      @update="handleUpdateRole"
      @save="handleSaveRole"
    >
    </CustomRoleModal>
    <!-- <pre>{{ items }}</pre> -->
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import UserRolePermissionModal from "@/views/GlobalComponents/Modals/UserRolePermissionModal.vue";
import CustomRoleModal from "@/views/GlobalComponents/Modals/CustomRoleModal.vue";
import AdminOwners from "../components/AdminOwners.vue";

export default {
  data() {
    return {
      headers: [
        { text: "Member", value: "user" },
        { text: "Title", value: "title" },
        { text: "Email Address", value: "email", sortable: true },
        { text: "Roles", value: "roles", sortable: true },
        { value: "actions", sortable: false, align: "center" },
      ],
      items: [],
      loading: false,
      selectedUser: null,
      userModal: false,
      selectedRole: null,
      roleModal: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("roles", ["workspaceRoles"]),
  },
  created() {
    this.fetchUsers();
    this.fetchAppPermissions();
    this.fetchWorkspaceRoles(this.user.team_id);
  },
  methods: {
    onSelect(val) {
      this.items = [val];
    },
    ...mapActions("roles", ["fetchAppPermissions", "fetchWorkspaceRoles"]),
    fetchUsers() {
      this.loading = true;
      this.$axios
        .get(`api/workspaces/${this.user.team_id}/users?with=roles`)
        .then(({ data }) => {
          this.items = data;
        })
        .finally(() => (this.loading = false));
    },
    updateUser(open, user) {
      this.userModal = open;
      this.selectedUser = user;
    },
    updateRole(open, role) {
      this.selectedRole = role;
      this.roleModal = open;
    },
    handleUpdateRole(payload) {
      this.$axios
        .put(`api/workspaces/${this.user.team_id}/roles/${payload.id}`, payload)
        .then(({ data }) => {
          this.fetchWorkspaceRoles(this.user.team_id);
          this.appToast("Success! Role updated.", "success", "Success");
          this.roleModal = false;
        });
    },
    handleSaveRole(payload) {
      this.$axios
        .post(`api/workspaces/${this.user.team_id}/roles`, payload)
        .then(({ data }) => {
          this.fetchWorkspaceRoles(this.user.team_id);
          this.appToast("Success! New role added.", "success", "Success");
          this.roleModal = false;
        });
    },
    handleChanges(payload) {
      this.$axios
        .post(`api/user/${payload.user_id}/roles-permissions`, payload)
        .then(({ data }) => {
          this.fetchUsers();
          this.appToast(
            "Success! User role and permissions updated.",
            "success"
          );
        })
        .finally(() => this.updateUser(false, null));
    },
  },
  components: { UserRolePermissionModal, CustomRoleModal, AdminOwners },
};
</script>

<style lang="scss" scoped></style>
